import { useContext, useState } from "react";
import { setApiApp, newApiApp } from "./localStore";
import { UserContext } from "./UserContext";
import { styled } from "ui/stitches.config";
import { Font23Bold, Font12Regular, Font14Regular } from "typography/typography";
import { useMutation } from "@apollo/client";
import { LoginMutation } from "core/user";
import { GqlStatus } from "ui/components/GqlStatus";
import GoogleIcon from 'GoogleIcon.svg';
import { Config } from "core/config";

type LegacyLoginProps = {
  apiKey?: string;
  projKey?: string;
  token?: string;
  accountKey?: string;
  onLogin?: () => void;
}

type LoginProps = {
  onLogin?: () => void;
}

const LoginStyle = styled('div', {
  padding: '30px 0',
  maxWidth: '400px',
  margin: '0 auto',
  input: {
    display: 'block',
    fontSize: 14,
    lineHeight: 1.4,
    padding: '12px',
    width: '94%',
    borderRadius: '8px',
    marginBottom: '15px',
    background: 'none',
    color: '$text1',
    outline: 'none',
    border: '1px solid $border1',
  },
  '.label': {
    color: '$label',
    display: 'flex',
    paddingBottom: '8px',
  },
  '.button-wrapper': {
    display: 'flex',
    margin: '40px 0 0',
    justifyContent: 'end',
  },
  'button': {
    padding: '8px 12px',
    background: '#d1fd4a',
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
  },
  'h2': {
    padding: '0 0 30px',
    textAlign: 'center',
  },
  '.g-link': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    background: '$colorWhite',
    padding: '8px 16px',
    color: '$textRev',
    borderRadius: '4px',
    '&:hover': {
      background: '$colorWhite',
      color: '$textRev',
    }
  },
  '.alias': {
    textAlign: 'center',
    margin: '12px 0',
    color: '$label',
  }
});

export function LegacyLogin(defaults: LegacyLoginProps = {}) {
  const userContext = useContext(UserContext);
  const [projKey, setProjKey] = useState<string>(defaults.projKey || '');
  const [apiKey, setApiKey] = useState<string>(defaults.apiKey || '');
  const [accountKey, setAccountKey] = useState<string>(defaults.accountKey || '');
  const [token, setToken] = useState<string>(defaults.token || '');

  const doLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setApiApp({
      ...(newApiApp()),
      apiKey,
      projKey,
      token: token || null,
    });
    userContext.setRequireRefresh();
    if (defaults.onLogin) {
      setTimeout(defaults.onLogin, 500);
    }
  }

  return (
    <div>
      <form action="" onSubmit={doLogin}>
        <LoginStyle>
          <Font23Bold>Login to Falcon</Font23Bold>
          <div>
            <Font12Regular className="label">Project Key</Font12Regular>
            <input type="text" value={projKey} placeholder="Project Key" onChange={(e) => setProjKey(e.target.value)} />
          </div>
          <div>
            <Font12Regular className="label">API Key</Font12Regular>
            <input type="text" value={apiKey} placeholder="API Key" onChange={(e) => setApiKey(e.target.value)}  />
          </div>
          <div>
            <Font12Regular className="label">Account Key</Font12Regular>
            <input type="text" value={accountKey} placeholder="Account Key" onChange={(e) => setAccountKey(e.target.value)}  />
          </div>
          <div>
            <Font12Regular className="label">Token</Font12Regular>
            <input type="text" value={token} placeholder="Token" onChange={(e) => setToken(e.target.value)}  />
          </div>
          <div className="button-wrapper">
            <button type="submit">
              <Font14Regular>Login</Font14Regular>
            </button>
          </div>
        </LoginStyle>
      </form>
    </div>
  );
}

export function Login(defaults: LoginProps = {}) {
  const userContext = useContext(UserContext);
  const parts = window.location.href.split('/');
  const basePath = `${parts[0]}//${parts[2]}`;
  const nextUrl = `${basePath}/pages/onLoginCallback/?next=${encodeURIComponent(window.location.href)}`;
  const googleLoginUrl = `${Config.apiUrl}user/oauth/google-auth/?json=0&next_url=${encodeURIComponent(nextUrl)}`;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [ login, { error, loading, data }] = useMutation(LoginMutation);

  const doLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login({ variables: { email, password } }).then((result) => {
      console.log('Login', result);
      const token = result.data?.core_login.token || null;
      if (!token) {
        if (!result.data?.core_login.status) {
          console.error('Unknown error while logging in', result);
        }
        return;
      }

      setApiApp({
        ...(newApiApp()),
        token: token,
      });

      userContext.setRequireRefresh();
      if (defaults.onLogin) {
        setTimeout(defaults.onLogin, 500);
      }
    });
  }

  return (
    <div>
      <form action="" onSubmit={doLogin}>
        <LoginStyle>
          <Font23Bold>Login to Falcon</Font23Bold>
          <a className="g-link" href={googleLoginUrl}>
            <img
              src={GoogleIcon}
              alt="logo"
            />
            Continue with Google
          </a>
          <p className="alias">or</p>
          <div>
            <Font12Regular className="label">Email</Font12Regular>
            <input type="email" value={email} placeholder="name@mail.com" onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <Font12Regular className="label">Password</Font12Regular>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          
          <GqlStatus loading={loading} error={error} status={data?.core_login.status} />
          <div className="button-wrapper">
            <button type="submit" disabled={loading}>
              <Font14Regular>Login</Font14Regular>
            </button>
          </div>
        </LoginStyle>
      </form>
    </div>
  );
}
