import process from "process";

const isProd = process.env.NODE_ENV === "production";

export const Config = {
  // gqlSrc: process.env.REACT_APP_RZ_GQL_SRC || "https://api.sports.roanuz.com/v5/core/<PROJ_KEY>/graphql/",
  staticUrl: process.env.REACT_APP_RZ_STATIC_URL || '',
  apiUrl: process.env.REACT_APP_RZ_API_URL,
  matchPollInterval: process.env.REACT_APP_MATCH_POLL_INTERVAL || 5000,
  isProd,
} as const;
